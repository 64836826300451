.cms-section{
  background: $white;
}

.cms-title{
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}

.cms-type{
  margin-top: 24px;

  @media (max-width: 992px) {
    &:nth-child(2n + 1){
      clear: both;
    }
  }

  @media (max-width: 480px){
    width: 100%;
  }
}

.cms-type-name{
  font-size: 16px;

  .indicator{
    display: inline-block;
    margin-left: 4px;
    background: #d4d4d4;
  }
}

.cms-text{
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
  margin-bottom: 0;
}

.cms-not-calculated{
  font-size: 12px;
  line-height: 18px;
  color: rgba(0,0,0,0.7);
  font-style: italic;
}

.cms-learn-why{
  display: inline-block;
  position: relative;
  padding: 6px 12px;
  font-weight: bold;
  font-style: normal;
  color: $black;
  background: darken($light-gray-bg, 4%);
  border-radius: 20px;
  margin-left: 8px;
}
