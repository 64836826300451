.sa-footer{
  background: $white;
  margin-top: 48px;
  border-top: 1px solid lighten($light-gray, 10%);
  padding: 35px 0;
}

.footer-logo{
  display: flex;
  align-items: center;
  font-size: 12px;

  img{
    max-width: 78px;
  }

  .app-name{
    font-size: 16px;
    margin-top: 0;
  }

  p{
    margin-bottom: 0;
  }

  .info-text{
    padding-left: 16px;
    max-width: 280px;
  }
}

.footer-nav{
  ul{
    padding: 0;
    list-style: none;

    a{
      display: block;
      font-size: 14px;
      padding: 6px;
      text-transform: uppercase;
      transition: all 0.1s ease-out;

      &:hover{
        opacity: 0.65;
        text-decoration: none;
      }
    }
  }
}

.footer-logo{
  @media screen and (max-width: 767px) {
    display: block;
    text-align: center;

    .info-text {
      max-width: 480px;
      margin: 16px auto;
    }
  }
}

.partners{
  img{
    width: 30%;
    max-width: 160px;
    margin-left: 16px;
  }

  @media screen and (max-width: 767px){
    clear: both;
    padding-top: 32px;
    text-align: center;

    img{
      margin-left: 0;
      margin-right: 32px;
    }
  }
}

.footer-nav{
  @media screen and (max-width: 767px){
    ul{
      text-align: center;
      margin-top: 32px;
    }

    li{
      display: inline-block;
    }
  }

  @media screen and (max-width: 767px){
    clear: both;
    padding-top: 32px;
    text-align: center;

    img{
      margin-left: 0;
      margin-right: 32px;
    }
  }
}

.footer-nav{
  @media screen and (max-width: 767px){
    ul{
      text-align: center;
      margin-top: 32px;
    }

    li{
      display: inline-block;
    }
  }
}