.edit-form-wrapper{
  max-width: 600px;
  margin: 0 auto;

  .form-title{
    margin-bottom: 24px;
    small{
      display: block;
      margin-bottom: 6px;
    }
  }
}

.shelter-edit-form{
  padding: 24px;
  background: $white;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.15);

  fieldset{
    padding: 12px;
    border: 1px solid transparentize($black, 0.9);
    border-radius: 2px;
    margin-bottom: 24px;
  }

  legend{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    padding: 0 6px;
    color: $black;
  }

  .fields{
    display: flex;

    label{
      display: block;
      margin-bottom: 4px;
      opacity: 0.6;
    }

    .eng,.nep{
      width: 50%;
    }
  }

  input{
    min-width: 220px;
    padding: 8px 10px;
    color: transparentize($black, 0.3);
    border: 1px solid transparent;
    background: darken($light-gray-bg, 4%);
    border-radius: 2px;
    transition: all 0.1s ease-in-out;

    &:focus{
      color: $black;
      outline: none;
      background: darken($light-gray-bg, 2%);
      border-color: darken($light-gray-bg, 10%);
    }
  }

  .actions{
    padding-top: 16px;
    margin-bottom: 24px;
  }

  .pill-btn {
    padding: 12px 32px;
    border-radius: 24px;

    &.pill-btn-cancel{
      margin-left: 24px;
    }
  }
}