.barchart-wrapper{
  background: $white;
  padding-left: 0;
  padding-right: 0;
}

.barchart-title{
  font-size: 14px;

  .text{
    display: inline-block;
    font-weight: bold;
  }

  .indicators{
    text-align: right;
    float: right;
    
    @media (max-width: 900px){
      float: none;
      text-align: left;
      margin-top: 8px;
    }
  }
  
  .indicator {
    display: inline-block;
    padding-right: 35px;
    font-size: 12px;
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 10px;
      height: 10px;
      background-color: #000;
      margin-right: 8px;
    }

    &:last-child{
      padding-right: 0;
    }
  }
  
  .fastest{
    &:before {
      background-color: $green;
    }
  }
  .average{
    &:before {
      background-color: $blue;
    }
  }
  .slowest{
    &:before {
      background-color: $red;
    }
  }
}

.barchart{
  padding: 0px;
}