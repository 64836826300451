.monitor-detail-section {
  background: $white;
  .pill-btn {
    margin-bottom: 32px;
  }
}

.monitor-details {
  display: flex;
  
  .monitors-profile-pic{
    @media (max-width: 992px) and (min-width: 769px) {
      img{
        max-width: 75px;
      }
    }

    @media (max-width: 768px){
      img{
        max-width: 60px;
      }
    }
  }

  .details {
    margin-left: 24px;

    @media (max-width: 992px) and (min-width: 768px) {
      margin-left: 16px;
    }

    @media (max-width: 480px) {
      margin-left: 16px;
    }

    .name {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 15px;
      margin-right: 30px;
      display: inline-block;
      vertical-align: middle;
    }

    .pill-btn {
      vertical-align: middle;
      box-shadow: none;
      font-size: 12px;
      line-height: 16px;
      padding-left: 30px;
      margin-bottom: 14px;
      background-color: darken($light-gray-bg, 5%);
      background-position: 12px center;
      background-repeat: no-repeat;

      &.edit-btn {
        background-image: url('/../images/edit-icon.svg');
        margin-right: 12px;
      }

      &.change-btn {
        background-image: url('/../images/key-icon.svg');
      }

      &:hover {
        background-color: darken($light-gray-bg, 10%);
        color: $black;
      }

      &.delete-btn {
        background-image: url('/../images/delete-icon.svg');
      }
    }

    @at-root .associated-partner {
      margin-top: 24px;

      @at-root .associated-partner-title {
        opacity: 0.5;
        font-size: 14px;
        font-style: italic;
      }

      @at-root .associated-partner-logo {
        max-height: 48px;
      }
    }
  }
}

.monitoring-homes {
  padding-left: 40px;
  position: relative;
  border-left: 1px solid $light-gray;

  @media (max-width: 992px) and (min-width: 768px){
    padding-left: 16px;
  }

  @media (max-width: 767px){
    margin-top: 32px;
    padding-left: 15px;
  }

  &:after {
    content: '';
    display: block;
    height: 25px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 40%, #FFFFFF);
  }
}

.monitoring-homes-title {
  font-size: 20px;
  font-weight: 500;
  margin-top: 0;
}

.monitoring-homes-list-wrapper {
  max-height: 230px;
  overflow-y: auto;
}

table.monitoring-homes-list {
  width: 100%;

  tr {
    border-bottom: 1px solid transparentize($light-gray, 0.8);

    td:first-child {
      padding-left: 0;

      @media (max-width: 992px) and (min-width: 768px){
        padding-right: 0;
      }
    }

    td:last-child {
      padding-right: 0;
    }
  }

  td {
    padding: 12px;

    @media (max-width: 992px) and (min-width: 768px){
      padding: 12px 4px;
      font-size: 12px;
    }

    @media (max-width: 480px){
      padding: 12px 4px;
      font-size: 11px;
    }

    &.stage-icon {
      text-align: center;
      img {
        width: auto;
        max-height: 26px;

        @media (max-width: 400px){
          max-height: 20px;
        }
      }
    }
  }

  .problems {
    @media (max-width: 480px){
      min-width: 96px;
    }

    span {
      display: block;
      position: relative;
      padding-left: 10px;
      font-size: 12px;
      color: $red;

      &:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background: $red;
      }
    }
  }

  .date {
    font-style: italic;
    small {
      font-size: 11px;
      opacity: 0.5;
      margin-right: 6px;
    }
  }
}

.not-begun-monitoring {
  margin-bottom: 16px;
}

// Monitor's recent activity section styles
@import 'recent-activity';


// QA edit styles
.monitoring-info-title{
  font-weight: normal;
  margin-top: 24px;
  margin-bottom: 36px;
}

.home-detail{
  display: flex;
  margin-bottom: 36px;
  align-items: flex-end;

  .nissa{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
  }

  .name{
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 18px;
  }

  .red-book{
    margin-bottom: 0;
  }

  .gray-text{
    opacity: 0.5;
    margin-bottom: 0;
  }

  .current-stage{
    margin-right: 24px;
  }

}

.monitor-info{
  margin-left: auto;

  p{
    font-style: italic;
    font-size: 12px;
    margin-bottom: 0;
  }
}
