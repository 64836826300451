.monitor-form {
  max-width: 400px;
  margin: 0 auto;

  .monitor-form-title {
    padding-bottom: 12px;

    img {
      height: 18px;
      width: auto;
      margin-right: 6px;
      margin-top: -5px;
      opacity: 0.5;
    }
  }
}

.monitor-form-wrapper {
  background: $white;
  padding: 24px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);

  .form-group {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 24px;

    .help-block {
      color: $red;
      font-size: 13px;
      strong {
        font-weight: normal;
      }
    }

    &.has-error {
      label {
        //color: $red;
      }
      input {
        border-color: $red;
      }
    }
  }

  label {
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
    opacity: 0.5;
  }

  input {
    width: 100%;
    display: block;
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 2px;
    transition: all 0.3s ease-in-out;

    &:focus {
      outline: none;
      border-color: $black;
    }
  }

  .pill-btn {
    padding: 10px 24px;
    font-size: 14px;
    border-radius: 50px;
    margin-top: 25px;
    
    &.pill-btn-cancel{
      margin-left: 12px;
    }
  }

  .help-text{
    font-size: 12px;
    font-weight: normal;
    margin-left: 4px;
    font-style: italic;
  }
}