.about-hero {
  background: url('/../images/about-hero-bg.svg') no-repeat center center;
  background-size: cover;

  .fluid-section {
    background: transparentize($white, 0.2);
  }
}

.about-hero-content {
  max-width: 600px;
  margin: 50px auto;

  @media (max-width: 768px){
    margin: 36px auto;
    padding: 0 15px;
  }

  @media (max-width: 480px){
    margin: 24px auto;
  }

  .about-title {
    font-size: 32px;
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 50px;

    @media (max-width: 768px){
      margin-bottom: 36px;
    }

    @media (max-width: 480px){
      font-size: 28px;
      margin-bottom: 24px;
    }
  }

  .about-text {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 0;

    @media (max-width: 768px){
      font-size: 16px;
      line-height: 22px;
    }

    @media (max-width: 480px){
      font-size: 14px;
    }
  }
}

.glimpse-title {
  font-weight: 300;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 42px;

  @media (max-width: 768px){
    font-size: 20px;
    margin-bottom: 32px;
  }

  @media (max-width: 480px){
    font-size: 18px;
    margin-bottom: 32px;
  }
}

#about-gallery {
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px){
    display: block;
  }

  .column {
    width: 33.33%;

    @media (max-width: 768px){
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
    }

    @media (max-width: 480px){
      display: block;
      margin-bottom: 0;
    }

    &.mid-column {
      margin: 0 16px;

      @media (max-width: 768px){
        margin: 0;
        margin-bottom: 32px;
      }
    }
  }
}

.about-img-wrapper {
  width: 100%;
  overflow: hidden;
  margin-bottom: 16px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px){
    width: 50%;
    margin: 0 15px;
  }

  @media (max-width: 480px){
    width: 100%;
    margin: 0 0 32px 0;
  }

  a {
    display: block;
    width: 100%;
    position: relative;
  }

  img {
    min-height: 100%;
    min-width: 300px;
    max-height: 170%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 768px){
      width: 100%;
    }
  }
}

.tall-img {
  a {
    height: 300px;

    @media (max-width: 768px){
      height: 220px;
    }
  }
}

.short-img {
  a {
    height: 220px;
  }
}

.ia-section{
  padding-bottom: 0;
}

.ia-content{
  display: flex;

  @media (max-width: 768px){
    display: block;
    padding: 0 30px;
  }

  @media (max-width: 480px){
    padding: 0 15px;
  }
}

.ia-logo-wrapper{
  margin-bottom: 42px;

  img{
    @media (max-width: 480px){
      width: 150px;
    }
  }
}

.field-visit{
  flex: 1 1 0;
  margin-right: 42px;

  @media (max-width: 768px){
    margin-right: 0;
    margin-bottom: 32px;
  } 

  img{
    max-width: 100%;
  }
}

.ia-info{
  flex: 1 1 0;

  .inner-wrapper {
    max-width: 550px;
    padding-top: 32px;
    padding-right: 15px;

    @media (max-width: 992px){
      padding-top: 0;
    }

    @media (max-width: 768px){
      max-width: 100%;
      padding-right: 0;
      padding-bottom: 45px;
    }
  }

  p{
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;

    @media (max-width: 992px){
      font-size: 14px;
      line-height: 22px;
    }

    a{
      text-decoration: underline;

      &:hover{
        opacity: 0.6;
      }
    }
  }
}