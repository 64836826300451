#app-navbar-collapse {
  .dropdown-menu {
    padding: 0;

    > li > a {
      padding: 6px 20px;
      font-size: 14px;
      font-weight: bold;
      color: #777;
    }
  }
}

.navbar-default {

  .navbar-nav.nav {
    @media (min-width: 768px) {
      display: none;
    }
  }
  .navbar-collapse {
    @media (max-width: 767px) {
      position: absolute;
      top: 100%;
      width: 100%;
      background: #fff;
      border-bottom: 1px solid #ddd;
      box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.15)
    }
  }
}

.logo {
  color: $brand-color-primary;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  padding: 11px 0;

  img {
    max-width: 35px;
    vertical-align: middle;
    margin-right: 6px;
    transition: all 0.2s ease-out;
  }

  &:hover {
    color: $black;
    text-decoration: none;
    opacity: 0.85;

    img {
      transform: rotate(15deg);
    }
  }
}

.helvetas-logo {
  display: inline-block;
  padding-left: 16px;
  margin-left: 16px;
  border-left: 1px solid #ddd;

  @media (max-width: 568px) {
    display: none;
  }

  img {
    max-height: 35px;
  }
}

.solidar-logo {
  display: inline-block;
  margin-left: 16px;

  @media (max-width: 568px) {
    display: none;
  }

  img {
    max-height: 35px;
  }
}

#app-navbar-collapse {
  flex: 1;

  > nav {
    display: none;
    padding-left: 24px;

    @media (max-width: 767px) {
      display: none !important;
    }
  }
}

.header-container {
  display: flex;
}

//Bootstrap overrides
.container > .navbar-header {
  margin-right: 0;
  margin-left: 0;
  flex: 0 0 411px;
  min-width: 411px;

  @media (max-width: 767px) {
    flex: 0 0 auto;
    width: 100%;
    min-width: auto;
  }
}

.nav-menu {
  display: inline-block;
  padding: 0;
  margin-bottom: 0;

  > li {
    display: inline-block;

    > a {
      display: block;
      padding: 20px 15px;
      font-size: 13px;
      text-transform: uppercase;

      &:hover {
        text-decoration: none;
        background: #f0f0f0;
      }

      &.active {
        background: #000000;
        color: #ffffff;
      }
    }
  }
}

.navbar {
  background: $navbar-bg;
  border-bottom: none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 0;
}

.navbar-default .navbar-brand {
  padding: 20px 15px;
}

.navbar-default .navbar-nav {

  margin-top: 0;
  margin-bottom: 0;

  > li > a {
    color: $text-color;
    text-transform: uppercase;
    padding: 20px 15px;
    font-size: 13px;

    @media (max-width: 768px) {
      padding: 12px 15px;
      font-size: 12px;
    }

    @media (max-width: 568px) {
      padding: 8px 15px;
      font-size: 11px;
    }

    &:hover {
      background: darken($white, 4%);
      color: $text-color;
    }

    &.active {
      background: $nav-link-active-bg;
      color: $nav-link-active-text-color;

      &:hover {
        background: $nav-link-active-bg;
        color: $nav-link-active-text-color;
      }
    }
  }
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  color: $text-color;
}

.navbar-default {
  .navbar-toggle {
    border-color: transparent;
    padding: 15px;
    margin-top: 6px;
    margin-right: -15px;

    .icon-bar {
      width: 18px;
      background-color: $black;
      transition: all 0.1s ease-in-out;
    }

    &:hover, &:active, &:focus {
      background: transparent;
    }

    &:hover {
      .icon-bar {
        opacity: 0.6;
      }
    }
  }
}

.priority-nav__wrapper {
  display: inline-block;
  vertical-align: middle;

  button {
    border: none;
    vertical-align: middle;
    background: transparent;
    padding: 19px 15px;
    font-size: 14px;
    text-transform: capitalize;
    border-radius: 0;

    &:hover {
      background: #f0f0f0;
    }

    &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 16px;
      width: 4px;
      background: url(/fonts/ic_more.svg?fd3adfb…) no-repeat;
      top: -1px;
      background-size: contain;
      background-position: center;
      position: relative;
      margin-left: 6px;
      opacity: 0.65
    }
  }
}

.priority-nav__dropdown {
  margin: 0;
  padding: 0;
  text-align: left;
  list-style: none;
  top: 100%;
  right: 0;
  background: #fff;
  border: 4px;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  li {
    &:first-child a {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child a {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  a {
    display: block;
    padding: 8px 16px;
    text-align: left;

    &:hover {
      text-decoration: none;
      background: #f5f5f5;
    }
  }

  .dropdown-menu {
    width: 100%;
    min-width: auto;
    position: relative;
    border: none;
    padding: 0;
    margin: 0;

    > li {
      > a {
        &:hover {
          background: #f5f5f5;
        }
      }
    }
  }
}


 
