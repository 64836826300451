.problems-visualization-section {
  background: $white;

  @media (max-width: 480px){
    padding: 32px 0;
  }
}

.visualization-wrapper {
  display: flex;
  align-items: stretch;
  justify-content: center;

  @media (max-width: 480px){
    display: block;
  }

  .v-divider {
    width: 1px;
    display: inline-block;
    background: $light-gray;
    transform: rotate(-15deg);
    margin: 0 45px;

    @media (max-width: 992px){
      height: 110px;
      align-self: center;
      margin: 0 18px;
    }

    @media (max-width: 480px){
      display: none;
    }
  }
}

.total-problems{
  align-self: center;
  margin-top: 10px;

  @media (min-width: 481px) and (max-width: 992px){
    margin-top: 18px;
  }

  @media (max-width: 480px){
    margin-top: 0;
    margin-bottom: 36px;
  }

  .count{
    @media (max-width: 480px){
      display: flex;
    }
  }

  .count-label{
    @media (max-width: 480px){
      font-size: 16px;
      font-weight: bold;
      align-self: flex-end;
      margin-left: 8px;
    }
  }
}

.count-number {
  font-size: 86px;
  line-height: 90px;
  font-weight: bold;

  @media (max-width: 992px){
    font-size: 54px;
    line-height: 50px;
  }

  @media (max-width: 480px){
    font-size: 48px;
    line-height: 50px;
  }
}

.count-label {
  display: block;
  text-align: center;

  @media (max-width: 992px){
    font-size: 13px;
  }
}

.ackd-problems {
  display: flex;

  @media (max-width: 480px){
    display: block;
  }
}

.ackd-problems-count {
  display: flex;
  align-items: center;

  .count-number {
    margin-bottom: 0;
    margin-right: 16px;

    @media (max-width: 480px){
      margin-right: 8px;
    }
  }

  .count-label {
    max-width: 180px;
    text-align: left;

    @media (min-width: 481px) and (max-width: 992px){
      max-width: 120px;
    }

    @media (max-width: 480px){
      max-width: auto;
    }

    strong {
      display: block;
      font-size: 24px;
      line-height: 28px;

      @media (max-width: 992px){
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
}

.ackd-problems-percentage {
  display: flex;
  align-items: center;

  .problem-status{
    margin: 0 32px;

    @media (max-width: 992px){
      margin: 0 15px;
    }

    @media (max-width: 640px){
      margin-right: 0;
    }

    @media (max-width: 480px){
      display: block;
      width: 100%;
      margin-top: 6px;
      margin-left: 15px;
    }
  }

  p{
    font-size: 12px;
  }

  .percent {
    display: block;
    font-size: 24px;
    line-height: 22px;
    font-weight: bold;
    position: relative;

    @media (max-width: 480px){
      display: inline-block;
      margin-right: 4px;
    }
  }

  .irrelevant{
    color: transparentize($black, 0.5);
    margin-bottom: 0;
  }
}

.problem-status-indicator {
  height: 6px;
  width: 6px;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -12px;

  .resolution & {
    background: $green;
  }

  .irrelevant & {
    background: $red;
  }
}

#acknowledge-ring{
  @media (max-width: 700px){
    display: none;
  }
}
