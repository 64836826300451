@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');

*{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  color: $text-color;
  background: $light-gray-bg;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;

  @media (max-width: 768px){
    font-size: 13px;
  }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  font-weight: bold;
}

a{
  color: $black;
  cursor: pointer;

  &:hover{
    color: initial;
  }

  &:active,&:visited,&:focus{
    color: initial;
    text-decoration: none;
  }
}

button{
  &:focus{
    outline: none;
  }

  &:active{
    opacity: 0.5;
  }
}

section{
  //overflow: hidden;
  &:after,&:before{
    content: " ";
    display: block;
    height: 0;
    clear: both;
  }
}

input[type=number] {
  -moz-appearance:textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

legend{
  display: inline-block;
  width: auto;
  border-bottom: none;
}

