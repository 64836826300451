.shelters-list-wrapper {
  padding: 50px 0;
}

.list-header {
  text-align: center;

  > p {
    font-size: 12px;
    opacity: 0.5;
  }

  .nav.nav-tabs {
    display: inline-block;
    background: $white;
    border: 1px solid $black;
    border-radius: 4px;
    margin-bottom: 32px;
    overflow: hidden;

    > li {
      margin-bottom: 0;
      &.active{
        > a {
          background: $black;
          color: $white;
        }
      }
      > a {
        font-weight: bold;
        border: none;
        border-radius: 0;
        padding: 12px 28px;
        margin: 0;
        color: $black;

        @media (max-width: 480px){
          font-size: 11px;
        }
      }
    }
  }
}

.shelter-list{
  .owner-name{
    color: $text-color;
    font-weight: bold;
  }

  .problems-cell{
    display: block;;
    position: relative;

    .problem-count-indicator{
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $red;
      left: auto!important;
      margin-right: 4px;

      &.no-problem{
        background: $green;
      }
    }

    .dropdown-wrapper{
      position: relative;
    }

    .dropdown-toggle{
      position: relative;
    }
  }
}

.dropdown-toggle{
  cursor: pointer;
}

.problems-list-menu{
  width: 320px;
  padding: 8px 12px;
  top: 115%;
  max-height: 250px;
  overflow-y: auto;

  @media (max-width: 768px){
    font-size: 12px;
  }

  @media (max-width: 480px){
    width: 250px;
    right: 0;
    left: auto;
  }

  >li{
    padding: 6px 0 6px 16px;
    position: relative;



    &:before{
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $red;
      position: absolute;
      top: 13px;
      left: 0;

      @media (max-width: 768px){
        top: 9px;
      }
    }

    &.fixed-problem{
      color: transparentize($black, 0.5);

      &:before{
        background: $green;
      }
    }
  }
}

.has-indicator{

}
