$statHeight: 120px;

.stats{
  background: $white;
  border-bottom: 4px solid #eee;
  position: relative;

  @media (max-width: 480px){
    height: $statHeight - 3px;
    box-shadow: 0 2px 15px rgba(0,0,0,0.35);
  }

  >.container{
    position: relative;
    display: flex;
    justify-content: space-between;
    z-index: 100;
    transition: all 0.5s ease-in-out;

    @media (max-width: 480px){
      flex-direction: column;
      max-height: $statHeight + 5px;
      padding: 0;
      overflow: hidden;

      &.expanded{
        max-height: 2000px;
        background: $white;
        box-shadow: 0 2px 5px rgba(0,0,0,0.15);
      }

      .filter:last-child{
        box-shadow: 0 9px 15px -2px rgba(0, 0, 0, 0.15);
        border-bottom: 1px solid #ccc;
      }
    }
  }

  .filter{
    position: relative;
    transition: all 0.15s ease-in-out;

    a{
      display: block;
      padding-top: 32px;
      padding-bottom: 24px;
      opacity: 0.5;
      
      @media (max-width: 767px){
        padding-top: 20px;
        padding-bottom: 16px;
      }

      @media (max-width: 480px){
        display: inline-block;
        opacity: 0.75;
      }

      &:hover{
        text-decoration: none;
      }
    }

    &:before{
      content: '';
      display: none;
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background: #000;
    }

    &:hover{
      opacity: 1;

      @media (min-width: 481px){
        &:before{
          display: block;
        }
      }

      *{
          cursor: pointer;
      }
    }

    &.inactive{
      @media (max-width: 480px){
        order: 2;
        background: $white;
        border-bottom: 1px dashed #eee;

        a{
          padding-top: 6px;
          padding-bottom: 6px;

          .count{
            margin-top: 4px;
          }
        }
      }
    }

    &.active{
      a {
        opacity: 1;
      }

      @media (max-width: 480px){
        order: 1;
        margin-bottom: 12px;
      }

      &:before{
        display: block;
      }

      &:after{
        content: '';
        position: absolute;
        bottom: -9px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-width: 6px 6px 0 6px;
        border-color: $black transparent transparent transparent;
        border-style: solid;
      }
    }

    &.disabled{
      &:hover{
        opacity: 0.5;
        cursor: not-allowed;
        &:before{
          display: none;
        }
      }

      *{
        &:hover{
          cursor: not-allowed;
        }
      }
    }
  }
}

.total-shelters{
  font-size: 14px;
  flex-grow: 0.16;
  flex-shrink: 0.16;
  
  @media (max-width: 992px){
    flex: 0 0 140px;
  }

  @media (max-width: 767px){
    flex: 0 0 80px;
    font-size: 12px;
    margin-top: 4px;
  }

  @media (max-width: 480px){
    margin-top: 0px;
  }

  >span{
    display: block;
    font-size: 90px;
    font-weight: bold;
    line-height: 70px;
    margin-bottom: 20px;
    
    @media (max-width: 992px){
      font-size: 75px;
      line-height: 96px;
      margin-bottom: 0px;
    }

    @media (max-width: 767px){
      font-size: 42px;
      line-height: 60px;
    }
  }

  &.active{
    @media (max-width: 480px){
      margin-top: 0;
    }
  }
}

.stats .filter.inactive .no-shelters{
  padding-top: 20px;
  padding-bottom: 16px;
}

.stages{
  flex-grow: 0.16;
  flex-shrink: 0.16;

  @media (max-width: 992px){
    flex: 0 0 80px;
  }

  @media (max-width: 767px){
    flex: 0 0 60px;

    img{
      max-width: 36px;
    }

    &.complete-stage{
      img{
        max-width: 56px;
      }
    }
  }

  .count{
    display: block;
    font-size: 23px;
    font-weight: bold;
    margin-top: 8px;

    @media (max-width: 992px){
      font-size: 20px;
    }

    @media (max-width: 767px){
      font-size: 18px;
      line-height: 20px;
    }
  }

  .stage-label{
    margin-bottom: 0;

    @media (max-width: 992px){
      font-size: 13px;
      line-height: 14px;
    }

    @media (max-width: 767px){
      font-size: 11px;
      line-height: 12px;
      margin-top: 4px;
    }
  }
}

.hide-stats-btn{    
  position: absolute;
  right: 15px;
  bottom: 2px;
  font-size: 11px;
  padding: 4px 6px;
  font-weight: bold;
  background: none;
  border: none;
  color: #555;
  z-index: 110;

  @media (min-width: 481px){
    display: none;
  }

  .caret{
    width: 0;
    height: 0;
    border-top: 4px solid;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
}