.monitoring-progress {
  padding: 24px 16px 0 50px;
  text-align: right;

  @media screen and (max-width: 992px){
    width: 100%;
    padding: 15px 15px 0 15px;
  }
}

.progress-content {
  display: inline-block;
  text-align: left;

  @media screen and (min-width: 1200px){
    width: 520px;
  }

  @media screen and (max-width: 991px){
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
  }

  @media screen and (max-width: 820px){
    padding: 0;
  }
}

.progress-title {
  padding: 42px 0 24px 0;
  border-bottom: 8px solid $default-border-color;
  margin: 0 0 12px 0;

  .homes{
    font-size: 48px;
    margin-left: 4px;
  }

  small {
    font-size: 14px;
    line-height: 20px;
    color: $text-color;
    margin-top: 8px;
  }

  .not-monitored-count{
    color: transparentize($black, 0.2);
  }
  
  .divider{
    width: 2px;
    align-self: stretch;
    background: transparentize($black, 0.9);
  }

  .overall-cms-wrapper{
    text-align: center;

    .overall-cms-label{
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      color: transparentize($black, 0.5);
      margin-bottom: 0;
    }

    .overall-cms-status{
      margin-bottom: 0;
      font-size: 12px;
      color: transparentize($black, 0.5);
    }
  }
}

.progress-number {
  font-size: 64px;
  font-weight: bold;
  line-height: 48px;
  margin-bottom: 8px;
}

.progress-table.table {
  margin-bottom: 12px;
  > tbody {
    > tr {
      padding: 30px 0;
      > td {
        border-top: none;
        vertical-align: middle;

        @media (max-width: 480px){
          padding: 8px 2px;
          font-size: 11px;
        }

        &.stage-title {
          font-weight: bold;
          padding-left: 16px;
          padding-right: 16px;

          @media (max-width: 480px){
            padding-left: 6px;
            padding-right: 6px;
          }
        }

        img{
          @media (max-width: 480px){
            max-height: 24px;
          }
        }

        &:first-child {
          text-align: center;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

.monitoring-progress-bar {
  border-radius: 2px;
  overflow: hidden;
  > div {
    width: 0;
    transition: all 0.3s ease-out;
  }
}

#progress {
  display: none;
  position: absolute;
  z-index: 1000;
  left: 400px;
  top: 300px;
  width: 200px;
  height: 20px;
  margin-top: -20px;
  margin-left: -100px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  padding: 2px;
}

#progress-bar {
  width: 0;
  height: 100%;
  background-color: #76A6FC;
  border-radius: 4px;
}

