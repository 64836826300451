.active-monitors-section {
  background: $white;

  @media (max-width: 480px) {
    padding-top: 18px;
    padding-bottom: 24px;
  }

  .container {
    max-width: 900px;
  }
}

.active-monitors-title {
  display: flex;
  padding-bottom: 24px;
  border-bottom: 1px solid $light-gray;
  margin-bottom: 24px;

  @media (max-width: 480px) {
    padding-bottom: 16px;
    margin-bottom: 12px;
  }

  @media (max-width: 400px) {
    display: block;
  }
}

.active-monitors-number {
  font-size: 94px;
  line-height: 50px;
  font-weight: bold;
  margin-right: 6px;

  @media (max-width: 768px) {
    font-size: 48px;
  }

  @media (max-width: 480px) {
    font-size: 32px;
  }
}

.active-monitors-label {
  font-size: 42px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
}

.add-monitor {
  margin-left: auto;
  align-self: flex-end;

  .pill-btn {
    padding: 10px 16px;

    @media (max-width: 480px) {
      font-size: 12px;
      padding: 8px 10px;
    }

    @media (max-width: 400px) {
      margin-top: 8px;
    }
  }

  .plus-icon {
    font-size: 18px;
    line-height: 16px;
    font-weight: bold;
    margin-right: 6px;
  }
}

.active-monitors-slider {
  @media (max-width: 940px) {
    padding: 0 16px;
  }

  @media (max-width: 940px) {
    padding: 0 32px;
  }
}

.monitor {
  padding: 16px;
  background: #f5f5f5;
  text-align: center;
  border-radius: 4px;
  min-height: 250px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);

  .detail {
    margin-bottom: 16px;

    small {
      display: block;
      opacity: 0.5;
    }
  }

  .detail-link {
    font-size: 14px;
    opacity: 0.6;
    text-decoration: underline;
  }
}

.monitor-name {
  margin-bottom: 16px;

  img {
    display: inline-block;
    max-width: 50px;
    margin: 0 auto 12px auto;
  }

  p {
    font-size: 16px;
    font-weight: bold;
  }
}

.owl-stage-outer {
  padding: 24px 0;
}

.owl-nav {
  .owl-prev, .owl-next {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background: $black url('/../images/chevron.png') no-repeat center center;

    &.disabled {
      opacity: 0.35;
    }
  }

  .owl-prev {
    left: -32px;
  }

  .owl-next {
    transform: translateY(-50%) rotate(180deg);
    right: -32px;
  }
}

.monitors-activity-list {
  @media (max-width: 768px) {
    .sa-table-row {
      background: transparent!important;
      box-shadow: none!important;

      &:hover {
        background: transparent!important;
      }
    }

    .sa-table-cell {
      &:before{
        left: 16px;
      }

      &.date-cell{
        padding-left: 72px!important;

        &:before{
          left: 0;
          font-weight: bold;
        }
      }

      &.activity {
        padding: 0!important;
        background: $white!important;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
        border-radius: 4px;

        .row {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }
}