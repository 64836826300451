// Top section (Beneficiary and Map) section styles
@import 'beneficiary';

// CMS section styles
@import 'cms';

// Monitoring visits section styles
@import 'monitoring-visits';

// Monitoring images section styles
@import 'monitoring-images';

// problem list styles
@import 'problem-list';

// notes list styles
@import 'notes-list';

// edit shelter detail styles
@import 'edit';
