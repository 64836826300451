.search-bar{
  background-color: $light-gray-bg;
  box-shadow: inset 0px -3px 20px rgba(0,0,0,0.1);

  @media (max-width: 768px){
    padding: 0;
  }

  .container{
    position: relative;
    padding-left: 50px;

    @media (max-width: 768px){
      padding-left: 35px;
    }
  }
}

.search-icon{
  position: absolute;
  top: 50%;
  left: 15px;
  height: 70px;
  width: 40px;
  transform: translateY(-50%);
  padding: 10px;
  border: none;
  background-color: transparent;
  text-align: center;
  cursor: pointer;

  @media (max-width: 768px){
    left: 5px;
  }
}

.search-form{
  width: 100%;
  display: flex;
  align-items: center;

  .pill-btn{
    margin-left: 24px;
  }

  .press-enter{
    position: absolute;
    top: 22px;
    right: 15px;

    @media (max-width: 768px){
      right: 5px;
    }
  }
}

.search-field{
  flex-grow: 1;
  height: 70px;
  line-height: 70px;
  padding: 0 65px 0 5px;
  border: none;
  background: transparent;
  
  &:focus{
    outline: none;
  }
}

.advance-filter-btn{
  &:after{
    content: '';
    display: inline-block;
    height: 0;
    width: 0;
    position: relative;
    top: -2px;
    border-top: 4px solid;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    margin-left: 8px;
  }
}

.search-info{
  background: $white;

  .container{
    padding: 42px 15px 42px 15px;
    border-bottom: 1px dashed transparentize($black, 0.9);
  }

  .caret-left{
    margin-right: 6px;
  }

  .search-result-text{
    font-size: 28px;
    font-weight: 300;
    margin-right: 32px;
    vertical-align: middle;

    strong{
      font-weight: 500;
    }
  }
}
