.beneficiary-problem-counts {
  display: flex;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 8px;

  @media (max-width: 568px){
    display: block;
    padding-bottom: 14px;
  }

  .problem-list-title {
    font-size: 14px;
    font-weight: bold;
    margin-right: 48px;
  }

  .count {
    display: inline-block;
    vertical-align: middle;
    margin-right: 32px;

    &:before {
      content: '';
      display: inline-block;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: $red;
      margin-right: 6px;
    }

    &.resolved {
      color: transparentize($text-color, 0.5);

      &:before {
        background: $green;
      }
    }
  }
}

.shelter-problem-list {
  padding-bottom: 75px;
  overflow: visible;

  .title {
    font-size: 24px;
    font-weight: bold;
  }

  .sa-table{
    @media (max-width: 768px){
      .stage{
        .stage-img-wrapper {
          width: auto;

          img{
            max-height: 24px;
          }
        }

        strong{
          line-height: 24px;
        }
      }

      .problem{
        >.row{
          display: block;
          margin-bottom: 6px;
          margin-right: 0;

          .col-xs-9, .col-xs-3{
            @media (max-width: 568px){
              width: 100%;
            }
          }

          .col-xs-3{
            @media (max-width: 568px){
              margin-top: 8px;
              padding-left: 35px;
            }
          }

          .problem-text{
            margin-bottom: 0;
            line-height: 18px;
          }

          &:last-child{
            .problem-text{
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .stage {
    display: flex;

    .stage-img-wrapper{
      width: 86px;
      margin-right: 8px;
      text-align: center;
    }
    img {
      max-width: 100%;
    }
  }

  .th-stage{
    padding-left: 15px!important;
  }

  .problem{
    .row{
      margin-bottom: 16px;
    }
  }

  @at-root .photos{
    .img-thumb{
      height: 32px;
      width: 32px;
      display: inline-block;
      border-radius: 4px;
      margin-right: 8px;
      box-shadow: 0 6px 25px -2px rgba(0, 0, 0, 0.25);
      overflow: hidden;
      cursor: pointer;

      &:last-child{
        margin-right: 0;
      }

      @media (max-width: 480px){
        margin-right: 4px;
      }
    }

    img{
      width: auto;
      height: 150%;
      cursor: pointer;
    }
  }

}

.modal.problem-img-modal{
  .modal-dialog {
    margin-top: 80px;
    display: inline-block;

    @media (min-width: 768px) {
      width: auto;
      max-width: 1170px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  .modal-content{
    padding: 50px 25px;
    border: none;
    border-radius: 2px;
  }

  .problem-text{
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 24px;
  }

  .problem-img-wrapper{
    display: flex;
    justify-content: center;

    >div{
      width: 50%;
    }

    p{
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  .before{
    margin-right: 40px;
  }

  img{
    max-width: 100%;
    border-radius: 2px;
    box-shadow: 0 6px 25px -2px rgba(0, 0, 0, 0.25);
  }

  .close-btn{
    height: 24px;
    width: 24px;
    position: fixed;
    top: 24px;
    right: 24px;
    border: none;
    background: url('/../images/close-icon-white.png') no-repeat center center;
    background-size: cover;
  }
}
