//Monitoring progress bar styles
@import 'monitoring-progress';
//Bar chart styles
@import 'barchart';
//IA report charts styles
@import 'ia-reports';

// Hero section styles
.hero-section {
  background: url('/../images/hero-img.jpg') no-repeat center center;
  background-size: cover;

  .fluid-section {
    background: transparentize($white, 0.1);
  }
}

.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  min-height: 60vh;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 768px) {
    width: 85%;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 0 15px;
  }
}

.hero-title {
  font-size: 52px;
  margin-bottom: 32px;
  font-weight: 300;

  @media (max-width: 768px) {
    font-size: 38px;
    margin-bottom: 16px;
  }

  @media (max-width: 480px) {
    font-size: 28px;
  }

  small {
    display: block;
    color: $black;
    font-size: 22px;
    margin-bottom: 12px;
    margin-top: 8px;

    @media (max-width: 768px) {
      font-size: 18px;
    }

    @media (max-width: 480px) {
      font-size: 15px;
    }
  }
}

.hero-text {
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    line-height: 18px;
  }

  a {
    text-decoration: underline;
    &:hover {
      opacity: 0.6;
    }
  }
}

.explore-btn {
  position: relative;
  padding: 12px 42px;
  background: $black;
  border-radius: 4px;
  border: none;
  color: $white;
  margin-top: 100px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    margin-top: 75px;
  }

  @media (max-width: 480px) {
    margin-top: 45px;
  }

  &:after {
    content: '';
    display: inline-block;
    height: 12px;
    width: 12px;
    position: absolute;
    bottom: -32px;
    left: 50%;
    transform: translateX(-50%);
    background: url('/../images/down-arrow.svg');

    @media (max-width:768px){
      animation: explore 0.5s ease-in-out infinite alternate;
    }
  }

  &:hover {
    background: lighten($black, 20%);

    &:after{
      animation: explore 0.5s ease-in-out infinite alternate;
    }
  }
}

.dashboard-charts-section {
  .fluid-section:first-child {
    padding-top: 24px;
  }

  .flexible-row {
    [class*="col-"] {
      padding: 30px 0;

      @media (max-width: 768px) {
        padding: 24px 0 0 0;
      }

      @media (max-width: 480px) {
        padding: 24px 15px 0 15px;
      }
    }
    > div:nth-child(2n + 1) {
      @media (max-width: 768px) {
        clear: both;
      }
    }

    .members-chart {
      @media (max-width: 768px) {
        &.clearfix {
          clear: none;
        }
      }
    }
  }
  .row {
    padding: 40px 0;

    @media (max-width: 768px) {
      padding: 12px 0;
    }
  }
}

.horizontal-barchart-wrapper {
  @media (max-width: 992px) {
    margin-bottom: 36px;

    &:last-child{
      margin-bottom: 0;
    }
  }
}

.scroll-icon {
  display: block;
  height: 24px;
  width: 24px;
  position: relative;
  top: -10px;
  background: url('/images/horizontal-scroll.svg') no-repeat center center;
  background-size: contain;
  opacity: 0.35;
  margin: 0 auto;

  @media (min-width: 471px) {
    display: none;

    &.scroll-icon-for-vertical{
      display: block;
    }
  }
}

.horizontal-barchart {
  overflow-x: auto;
}

.problems-each-stage {
  padding-top: 10px;

  .updated-date{
    margin-left: 4px;
    margin-right: 0;
  }
}

.stage-problems-charts{
  @media (max-width: 640px){
    display: block;
    overflow: hidden;

    .chart-wrapper{
      @media (max-width: 640px){
        width: 33.33%;
        float: left;
        margin-bottom: 32px;
        padding: 0 4px;
      }

      @media (max-width: 400px){
        width: 50%;
      }
    }
  }
}

//Average time length graph styles
.stage-completion-bar-wrapper {
  .chart-title {
    margin-bottom: 36px;
  }
}

.x-axis-indicator {
  display: inline-block;
  position: relative;
  font-size: 12px;
  opacity: 0.7;
  margin-bottom: 12px;

  &:after {
    content: '';
    display: block;
    height: 2px;
    width: 10px;
    background: $black;
    position: absolute;
    top: 50%;
    left: calc(100% + 6px);
    transform: translateY(-50%);
  }

  &:before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    top: 50%;
    left: calc(100% + 15px);
    transform: translateY(-50%);
    border-top: 3px solid transparent;
    border-left: 3px solid;
    border-bottom: 3px solid transparent;
  }

  .stage-completion-bar-wrapper & {
    margin-left: 80px;
  }

  &.barchart-houses-indicator {
    margin-left: 38px;
    margin-top: 16px;
  }

}

.barchart-months-indicator {
  display: block;
  opacity: 0.7;
  margin-top: 48px;
  font-size: 12px;
}

// Satisfaction of beneficiary ring chart styles
.satisfaction-chart {
  display: flex;
  justify-content: space-between;
  padding: 25px 0 0 0;

  > div {
    max-width: 180px;
    p {
      padding: 0 6px;
    }
  }

  @media (max-width: 480px) {
    padding-top: 0;

    > div {
      text-align: center;
      font-size: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.grid {
  stroke-width: 0.2px;
  opacity: 0.5;
}

.axis-y-satisfaction {
  opacity: 0.8;
  font-size: 12px;

  @media (max-width: 480px) {
    font-size: 11px;
  }
}

.axis-y-per {
  opacity: 0.5;
  font-size: 12.3px;
}

.axis-x-satisfaction {
  text {
    transform: translateY(-15em);
    font-size: 12px;
  }
}

.axis--x path {
  display: none;
  stroke: none;
}

.axis--y path {
  display: none;
  stroke: none;
  tick: none;
}

// Fix rate ring chart styles

.fix-rate-section-wrapper{
  @media (max-width: 640px) {
    background: #f5f5f5;
    margin: 24px 0;
  }
}

.fixed-rate-section {
  padding: 40px 0;

  @media (max-width: 768px) {
    padding-bottom: 20px;

    &:after{
      content: '';
      display: block;
      clear: both;
    }
  }
}

.fixed-rate-charts {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 640px) {
    display: block;
    overflow: hidden;
  }

  .fixed-chart {
    @media (max-width: 640px) {
      width: 33.33%;
      float: left;
      margin-bottom: 32px;
      padding: 0 4px;
    }

    @media (max-width: 400px) {
      width: 50%;
    }
  }

  .updated-date{
    margin-top: 8px;
    margin-left: 4px;
    margin-right: 4px;
  }
}

.poverty-line,.members-chart, .phone-chart{
  @media (max-width: 768px){
    padding-top: 24px;
  }
}

.download-file-wrapper{
  @media (max-width: 768px){
    padding: 18px 0;
  }
}

.download-btn{
  position: relative;
  padding: 12px 42px;
  background: #000000;
  border-radius: 4px;
  border: none;
  color: #ffffff !important;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;

  &:active, &:focus{
    background: #000000;
  }

  &:active:focus{
    background: #000000;
  }

  &:hover{
    background: #333333;
  }
}

@keyframes explore{
  0%{
    transform: translateX(-50%) translateY(0px);
  }
  100% {
    transform: translateX(-50%) translateY(6px);
  }
}

.last-update-info{
  font-style: italic;
  color: #585555;
}
