//temporary style, should be removed form here
.beneficiary-survey {
  display: flex;
  margin-bottom: 32px;
}

.stage-survey, .beneficiary-survey {
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.survey {
  padding: 16px;
  margin-right: 8px;
  border-radius: 2px;
  background: $white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  transition: all 0.15s ease-in-out;

  .stage-survey & {
    @media (min-width: 993px) {
      flex: 1;
    }
  }

  @media (max-width: 992px) {
    flex: none;
    width: 32%;
    margin-bottom: 16px;
  }

  @media (max-width: 992px) and (min-width: 569px) {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (max-width: 568px) {
    flex: none;
    width: 47%;
    margin-bottom: 16px;
  }

  @media (max-width: 568px) and (min-width: 401px) {
    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: 400px) {
    flex: none;
    width: 100%;
    margin-bottom: 16px;
    margin-right: 0;
  }

  .monitor {
    font-size: 12px;
    font-style: italic;
    margin-top: 24px;
    margin-bottom: 0;
    color: transparentize($black, 0.5);
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    text-decoration: none;
    box-shadow: 0 12px 25px -6px rgba(0, 0, 0, 0.25);
    transform: translateY(-3px);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
      transform: translateY(0px);
    }

    .survey-title {
      text-decoration: none;
    }
  }

  &.non_clickable{
    cursor: default;

    &:hover {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
      transform: translateY(0px);
      cursor: default;
    }
    .survey-title {
      text-decoration: none;
    }
  }
}

.survey-completion-date {
  font-size: 12px;
}

.survey-title {
  font-weight: bold;
  text-decoration: underline;
}

.stage-survey {
  display: flex;
}

.minusIcon, .plusIcon {
  height: 35px !important;
  width: 35px !important;
}

.minusIcon {
  background: #E7594F !important;
}

.plusIcon {
  background: #58C385 !important;
}

.memberCount {
  height: 52px;
  width: 65px;
  font-size: 52px;
  line-height: 35px;
  text-align: center;
  opacity: 0.4;
  border: none;
}

.input, .input:focus, .input:active, .input:hover {
  background: transparent;
}

.ans-edit-alert {
  max-width: 800px;
  margin: 36px auto 36px auto;
}