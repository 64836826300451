.added-by{
  text-decoration: underline;
}

.note-wrapper{
  position: relative;
  padding-right: 26px;
}

.note-text.more{
  display: none;
}

.note-toggle{
  height: 16px;
  width: 16px;
  display: block;
  position: absolute;
  top: 4px;
  right: 0;
  background: url('/../images/chevron-down-icon.svg') no-repeat center center;
  opacity: 0.5;
  transition: all 0.15s ease-in-out;
  cursor: pointer;

  &:hover{
    opacity: 1;
  }

  &.flipped{
    transform: rotate(180deg);
  }
}