.monitoring-image-gallery{
  @media (max-width: 992px){
    padding: 0;
  }

  @media (max-width: 1200px) {
    .owl-nav{
      .owl-prev {
        left: -8px!important;
      }
      .owl-next {
        right: -8px!important;
      }
    }
  }
}

.monitoring-image-carousel {

  .stage-name {
    opacity: 0.75;
    margin-top: 12px;
  }

  .owl-stage-outer {
    padding-bottom: 0;
  }

  .owl-item {
    a {
      display: block;
      height: 140px;
      overflow: hidden;
      position: relative;
      border-radius: 2px;

      p{
        position: absolute;
        z-index: 100;
        bottom: 0;
        right: 0;
        padding: 4px 8px;
        background: rgba(0,0,0,0.65);
        width: 100%;
        margin: 0;
        color: #fff;
        text-align: right;
        font-size: 13px;
        font-weight: bold;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
