.flex-item {
  display: flex;
}

.flex-item-space-between {
  @extend .flex-item;
  justify-content: space-between;
}

.flex-item-align-center {
  @extend .flex-item;
  align-items: center;
}

.clearfix{
  clear: both;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#app {
  flex-grow: 1;
}

.light-gray-bg {
  background: $light-gray-bg;
}

.fluid-section {
  padding: 50px 0;

  &.no-padding{
    @media (min-width: 769px) {
      padding: 0;
    }
  }

  @media screen and (min-width:1200px){
    padding: 4% 0;
  }
}

.help-icon{
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-left: 4px;
  background: #8d8d8d;
  text-align: center;
  font-size: 10px;
  line-height: 13px;
  font-weight: bold;
  color: $white;
  cursor: help;
  vertical-align: middle;
}

.help-popover{
  font-size: 14px;
  line-height: 22px;

  *{
    color: transparentize($black, 0.3);
  }
}

//Map styles

.map-wrapper {
  padding-left: 0;
  padding-right: 0;
}

.bg-white {
  background: $white;
}

.bg-red {
  background: #eb5757 !important;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.bg-orange {
  background: #f2994a !important;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.bg-green {
  background: #6fcf97 !important;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

#map {
  padding: 0;
  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 10px;
    position: absolute;
    top: 0;
    right: 100%;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.35);
    z-index: 500;
  }

  //.leaflet-control-container{
  //  position: absolute;
  //  top: 50%;
  //  transform: translateY(-50%);
  //  right: 0;
  //  z-index: 1000;
  //  overflow: hidden;
  //}
}

/*============ Graphs ============*/
#map {
  height: 555px;

  @media (max-width: 768px){
    height: 400px;
  }

  @media (max-width: 568px){
    height: 300px;
  }
}

.leaflet-control-container .leaflet-top {
  top: 50%;
  transform: translateY(-50%);
}

.leaflet-touch .leaflet-bar {
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
}

.leaflet-touch .leaflet-control-zoom-in {
  border-bottom-color: #e8e8e8;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 16px;
}

.barGraph {
  height: 500px;
  width: 100%;
}

/*========= BarChart =========*/

.barchart {
  margin-top: 20px;
  height: 425px;
  overflow-x: scroll;
}

#tooltip-no-tip {
  position: absolute;
}

#tooltip-no-tip p {
  margin-left: -50%;
  display: inline-block;
  top: -54px;
  position: absolute;
  text-align: center;
  width: 120px;
  height: auto;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
  pointer-events: none;
  color: #fff;
  z-index: 99;
  font-size: 14px;
  line-height: 20px;
}

.hide {
  display: none;
}

.caret-left {
  width: 0;
  height: 0;
  display: inline-block;
  position: relative;
  top: -1px;
  border-top: 4px solid transparent;
  border-right: 4px solid;
  border-bottom: 4px solid transparent;
}

.caret-down {
  width: 0;
  height: 0;
  display: inline-block;
  position: relative;
  top: -1px;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.pill-btn {
  display: inline-block;
  font-weight: bold;
  padding: 6px 12px;
  background: darken($light-gray-bg, 6%);
  border-radius: 20px;
  border: none;
  color: $text-color;
  box-shadow: 0 8px 15px -6px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    text-decoration: none;
    //color: $white;
    background: darken($light-gray-bg, 12%);
    box-shadow: 0 16px 15px -12px rgba(0, 0, 0, 0.5);
    transition: all 0.2s ease-in-out;
  }

  &:focus {
    outline: none;
  }

  &:active {
    opacity: 0.5;
  }

  &.pill-btn-black {
    background: $black;
    color: $white;

    &:hover {
      background: lighten($black, 20%);
    }
  }

}

.press-enter{
  display: none;
  width: 60px;
  background: url('/../images/enter-icon.svg') no-repeat left center;
  padding-left: 20px;
  min-height: 20px;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.25;
}

.search-field{
  &:focus{
    & + .press-enter{
      display: inline-block;
    }
  }
}

.sa-table-wrapper {
  max-width: 100%;
}

.sa-table {
  width: 100%;
  min-width: 768px;
  margin: auto;

  @media (max-width:768px){
    min-width: initial;
  }

  .sa-table-header {
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid $table-header-border-color;
    padding: 12px 0;

    @media (max-width:768px){
      display: none;
    }

    [class*="col-"]:first-child {
      padding-left: 15px;
    }

    [class*="col-"]:last-child {
      padding-right: 15px;
    }
  }
  .sa-table-body {
    background: $white;

    @media (max-width:768px){
      background: transparent;
      padding: 0 15px;
    }

    .sa-table-row {
      border-bottom: 1px solid $table-row-border-color;
      padding: 12px 0;

      &:hover {
        background: $light-gray-bg;
        //box-shadow: 0 3px 16px rgba(0,0,0,0.15);

        .table-link {
          text-decoration: underline;
        }
      }

      @media (max-width:768px){
        padding: 4px 0;
        background: $white;
        margin-bottom: 16px;
        border-bottom: 0;
        border-radius: 4px;
        box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
        transition: all 0.3s ease-in-out;

        &:hover{
          background: $white;
          box-shadow: 0px 6px 12px -4px rgba(0, 0, 0, 0.15);
        }
      }

      [class*="col-"]:first-child {
        padding-left: 15px;
      }

      [class*="col-"]:last-child {
        padding-right: 15px;
      }
    }
  }

  [class*="col-"] {
    padding: 0 8px;
  }

}

.sa-table-cell{
  @media (max-width:768px){
    display: block;
    float: none;
    width: 100%;
    font-size: 13px;
    padding: 8px 8px 8px 150px!important;

    &:before{
      content: attr(data-label);
      display: inline-block;
      vertical-align: top;
      position: absolute;
      top: 10px;
      left: 8px;
      font-size: 11px;
      color: #999;
      text-transform: uppercase;
    }
  }

  @media (max-width:480px){
    padding: 8px 8px 8px 100px!important;
    font-size: 12px;
  }
}

.sa-table-cell.no-data-cell {
  color: transparentize($black, 0.6);
}

.chart-title {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 18px;
  line-height: 18px;

  @media (max-width: 768px){
    margin-bottom: 12px;
  }

  @media (max-width: 480px){
    font-size: 12px;
  }
}

.chart_loader{
  opacity: 0.5;
  font-style: italic;
  margin-top: 32px;
  margin-bottom: 24px;
}

.updated-date{
  display: block;
  font-weight: normal;
  font-style: italic;
  font-size: 12px;
  color: #4f4f4f;
  margin-top: 2px;
}

.info-icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  border-radius: 16px;
  line-height: 14px;
  text-align: center;
  font-size: 11px;
  font-weight: 900;
  border: 2px solid $black;
  margin-right: 6px;
}

.has-tooltip {
  position: relative;
  cursor: default;
}

.sa-tooltip {
  display: block;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%) scale(0.7);
  min-width: 150px;
  opacity: 0;
  padding: 12px 16px;
  background: lighten($black, 20%);
  color: darken($white, 8%);
  font-weight: normal;
  line-height: 16px;
  border-radius: 15px;
  transition: all 0.15s ease-out;
  pointer-events: none;
  box-shadow: 0 2px 15px -1px rgba(0, 0, 0, 0.5);

  &:after {
    content: '';
    display: block;
    height: 0;
    width: 0;
    border-top: 8px solid lighten($black, 20%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.2s ease-out 0.1s;
  }
}

.has-tooltip {
  &:hover {
    .sa-tooltip {
      opacity: 1;
      bottom: calc(100% + 12px);
      border-radius: 4px;
      transition: all 0.2s ease-out;
      transform: translateX(-50%) scale(1);

      &:after {
        top: 98%;
      }
    }
  }
}

.problem-text {
  position: relative;
  padding-left: 20px;
  margin-bottom: 15px;
  line-height: 24px;

  &.resolved {
    color: transparentize($black, 0.5);
  }

  .right-arrow {
    margin-left: 8px;
  }

  .fixed-date {
    font-size: 12px;
    font-style: italic;
  }
}

.problem-indicator {
  position: absolute;
  top: 7px;
  left: 0;
  display: inline-block;
  align-self: flex-start;

  @media (max-width: 768px){
    top: 4px;
  }
}

.problem-status-btn {
  display: inline-block;
  color: lighten($black, 50%);
  font-style: italic;
  font-size: 12px;
  margin-left: 6px;
  cursor: pointer;

  &.acknowledged {
    background: url('/../images/check-icon.svg') no-repeat left center;
    padding-left: 12px;
  }

  &.marked-irrelevant {
    background: url('/../images/close-icon.svg') no-repeat left center;
    padding-left: 11px;
  }
}

.acknowledged-btn {
  background: darken($white, 5%);
  border: 1px solid $light-gray;
  border-radius: 20px;
  padding: 2px 8px;
  color: $black;
  font-size: 10px;
  font-weight: bold;
  line-height: 16px;
  text-transform: uppercase;
  margin-left: 6px;
  transition: all 0.2s ease-out;

  &.ack-active-btn {
    background: #000;
    border-color: #000;
    color: #fff;
  }
}

.popover {
  max-width: 340px;
  border-color: #ddd;
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.25);
}

.acknowledge-popover {
  padding: 20px;
  min-width: 320px;

  @media (max-width: 480px){
    padding: 12px;
    min-width: 280px;
    left: -10px!important;
  }

  .popover-title {
    position: relative;
    background: transparent !important;
    padding: 0 0 12px 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    border-bottom: none;

    @media (max-width: 480px){
      padding: 0;
      font-size: 13px;
      line-height: 18px;
      margin-right: 0;
      margin-left: 0;
    }

    span.acknowledged-date {
      display: block;
      font-size: 12px;
      font-style: italic;
      opacity: 0.5;
    }

    .edit-btn {
      position: absolute;
      top: 12px;
      right: 0;
      background: darken($light-gray-bg, 6%) url('/../images/edit-icon.svg') no-repeat;
      background-position: 12px center;
      font-size: 12px;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 30px;
      box-shadow: none;

      img {
        vertical-align: baseline;
        margin-right: 4px;
      }

      &:hover {
        background-color: lighten($dark-gray, 50%);
        color: $black;
      }
    }
  }

  .popover-content {
    padding: 0;
    margin-top: 14px;
    color: lighten($black, 30%);
  }

  textarea {
    display: block;
    width: 100%;
    padding: 8px;
    border: none;
    background: #f0f0f0;
    color: $black;
    border-radius: 4px;
    resize: none;
    transition: 0.2s ease-out;
    margin-bottom: 24px;

    @media (max-width: 480px){
      margin-bottom: 14px;
      font-size: 13px;
    }

    &:focus {
      background: #f2f2f2;
      outline: none;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    }
  }

  .acknowledge-btn, .irrelevant-btn {
    font-size: 12px;
    font-weight: 500;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    color: $white;
    margin-top: 8px;

    &.lg-btn {
      display: none;
    }

    &:disabled{
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .acknowledge-btn {
    background: $green;
  }

  .irrelevant-btn {
    background: $red;
    margin-right: 24px;

    @media (max-width: 480px){
      margin-right: 10px;
    }
  }

  &.acknowledge-edit-popover {
    .popover-title {
      padding: 0 75px 12px 0;
      border-bottom: 1px solid #ebebeb;
    }

    textarea {
      background: transparent;
      &:focus {
        background: #f2f2f2;
        outline: none;
      }
    }
  }
}

.container{
  @media screen and (min-width: 768px) and (max-width: 992px){
    width: 100%;
  }

  @media screen and (min-width: 769px) and (max-width: 992px){
    padding-right: 25px;
    padding-left: 25px;
  }
}

.pagination-wrapper {
  text-align: center;
  padding: 0 15px;

  .pagination {
    display: inline-block;

    > li {
      &.disabled {

        span {
          color: #888;
          &:hover {
            border-color: $black;
            color: #bbb;
          }
        }
      }
      span, a {
        border-radius: 0 !important;
        border-color: $black;
        color: $text-color;
        margin-bottom: 12px;
      }

      &.active {
        span {
          background-color: $black;
          color: $white;
          &:hover {
            border-color: $black;
            cursor: default;
          }
        }
      }
    }
  }
}

.modal-backdrop.in {
  opacity: 0.8;
}

/* Bootstrap overrides*/
.popover{
  min-width: 320px;
  color: $black;

  @media (max-width: 480px){
    min-width: 280px;
  }
}

.popover-title {
  background: transparent;
  margin-right: 12px;
  margin-left: 12px;
  padding: 12px 0;
}

// Lightbox JS style overrides
.lb-data {
  .lb-details {
    width: 100%;
    text-align: center;
    color: $white;

    .lb-caption {
      display: inline-block;
      margin-top: 24px;
      margin-bottom: 18px;
      font-size: 14px;
      color: $white;
    }

    .lb-number {
      font-size: 14px;
      color: $white;
    }
  }
}

.lb-data .lb-close {
  position: fixed;
  top: 24px;
  right: 24px;
  height: 24px;
  width: 24px;
  background-size: cover;
  z-index: 100;
}

.lb-nav a.lb-prev, .lb-nav a.lb-next {
  width: 32px;
  background-size: 100%;
  background-position: center;
  opacity: 1;
}

.lb-image {
  max-height: 70vh;
  width: auto !important;
}

.lightbox-title {
  font-size: 18px;
  font-weight: 300;
  color: $white;
  margin-bottom: 24px;
  line-height: 32px;
}

@media (max-width:767px) {
  .visible-xs {
    display: inline !important;
  }
}