//// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,600);

// Variables
@import "variables";

// Base styles
@import 'base';

// Common styles
@import 'common';

// Navigation
@import "navigation";

// Dashboard styles
@import "landing-page/landing-page";

// Shelter list page styles
@import "shelters/shelters";

// Shelter detail page styles
@import "shelter-detail/shelter-detail";

// Problems page styles
@import "problems/problems";

// Monitor detail page styles
@import "monitor-detail/monitor-detail";

// Monitors list page styles
@import "monitors/monitors";

// About page styles
@import "about/about";

// Footer styles
@import "footer";


