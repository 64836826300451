.ia-chart-number {
  font-size: 56px;
  font-weight: bold;
  line-height: 64px;

  @media (max-width: 480px){
    font-size: 42px;
    line-height: 30px;
  }
}

.phone-chart {
  .indicators-wrapper {
    margin-left: 32px;

    @media (max-width: 992px) {
      margin-left: 6px;
    }
  }

  .indicator {
    position: relative;
    padding-left: 12px;

    &:before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      position: absolute;
      left: 0;
      top: 6px;
      border-radius: 50%;
      background: #2D9CDB;
      margin-right: 6px;
    }

    &.smart-phone-indicator {
      &:before {
        background: #7CBEE4;
      }
    }

    &.internet-indicator {
      &:before {
        background: #CBE1ED;
      }
    }
  }
}

.stage-problems-charts {
  .stage {
    margin-top: 8px;
    margin-bottom: 4px;
  }
}

.monitors-number-wrapper{
  >*{
    margin-right: 34px;

    @media (max-width: 680px){
      margin-right: 12px;
    }
  }
  >div:last-child{
    margin-right: 0;
  }

}

.flexible-row{
  @media (max-width: 480px) {
    margin: 0 -15px;
  }
}

.homes-with-no-problems{
  @media (max-width: 768px){
    &.clearfix{
      clear:   none;
    }
  }
}

.problem-number-chart{
  p{
    margin-bottom: 0;
  }

  @media (max-width: 480px){
    width: 100%;
  }
}

.total-beneficiaries{
  @media (max-width: 540px) {
    width: 100%;
    margin-bottom: 24px;
  }
}

.monitors-number{
  @media (max-width: 540px) {
    width: 100%;
  }
}

.partner {
  display: flex;
  flex: 0.5;
  align-items: center;

  @media (max-width: 768px){
    flex: auto;
  }

  @media (max-width: 400px) {
    align-items: flex-start;
  }

  .monitor-percent {
    font-size: 24px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 0;
    margin-right: 12px;

    @media (max-width: 400px) {
      font-size: 18px;
      margin-right: 8px;
    }
  }

  .partner-name{
    color: transparentize($black, 0.5);
    margin-bottom: 6px;
  }
}

.members-chart{
  .chart-title{
    margin-bottom: 0;
  }

  @media (max-width: 480px){
    width: 100%;
  }
}

.note{
  font-size: 12px;
  font-style: italic;
  margin-top: 16px;
}