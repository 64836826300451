.monitor-recent-activity-title {
  display: flex;
  margin-bottom: 32px;

  @media (max-width: 480px){
    display: block;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
  }

}

.monitor-recent-activity-search {
  display: flex;
  min-width: 250px;
  margin-left: auto;
  border-bottom: 2px solid $light-gray;
  align-items: center;

  @media (max-width: 480px){
    margin-top: 16px;
  }

  .icon {
    display: inline-block;
    height: 12px;
    width: 12px;
    background: url('/../images/search-icon.png') no-repeat center center;
    background-size: 100%;
    margin-right: 6px;
    margin-top: 2px;
  }

  form {
    flex-grow: 1;
  }

  input {
    width: 100%;
    font-size: 12px;
    background: transparent;
    border: none;

    &:focus {
      outline: none;
    }
  }
}

.monitors-activity-list {
  .home-cell {
    a {
      text-decoration: underline;
    }
  }

  .activity {
    ul {
      list-style: none;
      padding: 0;

      li {
        padding: 6px 0;
        border-bottom: 1px solid lighten($black, 92%);
        overflow: hidden;

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }

  }

}

.view-more-btn {
  font-size: 12px;
  margin: 24px auto;


  .loading-icon{
    display: none;
    vertical-align: middle;
    height: 14px;
    width: 14px;
    margin-left: 6px;
    background: url('/../images/loading-icon.svg');
  }

  &[disabled]{
    opacity: 0.75;
    cursor: not-allowed;

    .loading-icon{
      display: inline-block;
    }
  }

}

.not-found-message {
  p {
    font-size: 14px;
    font-weight: bold;
    opacity: 0.5;
    padding-top: 24px;
    margin: 16px 0;
    border-top: 1px solid #ddd;
  }
}