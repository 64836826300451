//UI color
$white: #ffffff;
$black: #000000;
$red: #eb5757;
$green: #6fcf97;
$blue: #31cae3;
$orange: #f2994a;
$purple: #7E5ffB;
$pink: #fb5fc3;
$light-gray: #d4d4d4;
$dark-gray: #555555;

// Body
$body-bg: #ffffff;
$light-gray-bg: #fafafa;

//Nav bar

$navbar-bg: #ffffff;
$nav-link-active-bg: #000000;
$nav-link-active-text-color: #ffffff;


// Borders
$default-border-color: #000000;
$table-header-border-color: $default-border-color;
$table-row-border-color: lighten($table-header-border-color, 90%);

// Brands
$brand-color-primary: #000000;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #000000;
$text-color-light: #e8e8e8;
$text-color-white: #ffffff;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-color-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;
