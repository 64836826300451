.fluid-section{
  padding-right: 0;

  .map-wrapper{
    padding: 0;
  }
}

.beneficiary{
  padding-top: 40px;
  padding-bottom: 40px;

  @media (max-width: 480px) {
    padding-top: 32px;
    padding-bottom: 24px;
  }

  .caret-left{
    margin-right: 6px;
  }

  .beneficiary-content{
    float: right;
    padding-right: 24px;

    @media screen and (min-width: 1200px){
      width: 550px;
    }

    @media screen and (min-width: 992px) and (max-width: 1199px){
      width: 450px;
    }

    @media screen and (max-width: 991px){
      float: none;
      max-width: 750px;
      margin: 0 auto;
      padding: 0 15px;
    }

    @media screen and (max-width: 480px){
      float: none;
      max-width: 750px;
      margin: 0 auto;
      padding: 0;
    }
  }
}

.beneficiary-detail{
  display: flex;
  align-items: center;
  padding: 40px 0;

  @media (max-width: 480px) {
    padding: 24px 0 16px 0;
  }
}

.beneficiary-details-charts{
  display: flex;
  justify-content: space-between;

  &.public-view{
    justify-content: inherit;

    .fix-rate-chart-wrapper{
      margin-right: 48px;

      @media (max-width: 480px) {
        margin-right: 36px;
      }
    }
  }
}

.details-charts-wrapper{
  text-align: center;
  max-width: 120px;

  .ring-chart{
    display: block;
  }
}

.current-stage{
  align-self: flex-start;
  text-align: center;
  font-size: 14px;

  img{
    margin-bottom: 8px;
  }
  
  .stage-name{
    margin-bottom: 2px;
  }
  
  .completion-date{
    font-size: 12px;
    color: transparentize($black, 0.5);
  }
}

.beneficiary-name-address{
  padding: 0 25px;
  flex-grow: 1;

  .nissa-no{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;

    .pill-btn{
      font-size: 12px;
      box-shadow: none;
      .icon{
        display: inline-block;
        vertical-align: middle;
        height: 12px;
        width: 12px;
        background: url('../images/edit-icon.svg') no-repeat center;
        background-size: cover;
        margin-right: 6px;
        position: relative;
        top: -2px;
      }
      
      &:hover{
        background: darken($light-gray-bg, 12%);
        color: $black;
      }
    }
  }

  .name{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .verified, .not-verified{
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1px solid $green;
    background-size: 80%;
  }

  .verified{
    background: url('/../images/check-icon.svg') no-repeat center center;
  }

  .not-verified{
    border-color: $red;
    background: url('/../images/close-icon.svg') no-repeat center center;
  }

  .address{
    font-size: 14px;
    opacity: 0.5;
  }

  .view-detail-btn{
    background-color: darken($light-gray-bg, 4%);
    padding: 4px 16px;
    color: $text-color;
    font-weight: bold;
    line-height: 30px;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 2px 3px -1px rgba(0,0,0,0.25);
    transition: all 0.2s ease-in-out;

    .caret-down{
      margin-left: 12px;
    }

    &:hover{
      background: $black;
      color: $white;
      box-shadow: 0 10px 15px -6px rgba(0,0,0,0.5);
    }

  }

  @at-root .monitored-by{
    margin-top: 32px;
    margin-bottom: 0;

    .monitored-by-label{
      color: transparentize($black, 0.5);
      margin-right: 12px;
    }

    a{
      text-decoration: underline;

      &:hover{
        opacity: 0.6;
      }
    }
  }
}

.beneficiary-details-charts{
  label{
    font-size: 12px;
  }
}


.reset-location-btn{
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  padding: 10px 24px;
  background: #e0e0e0;
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.25);
  z-index: 1000;
  transition: all 0.15s ease-in-out;

  .reset-icon{
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    margin-right: 6px;
    background: url('/../images/reset-icon.svg') no-repeat center center;
  }

  &:hover{
    background: darken(#e0e0e0, 4%);
  }
}
