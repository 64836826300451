@import 'problems-visualization';

.problems-content{
  .search-bar{
    background-color: #f5f5f5;
    box-shadow: none;
    border-bottom: 1px solid #ddd;
  }
}

.search-result{
  margin-bottom: 50px;
}

.search-results-label{
  margin-left: 40px;

  @media (max-width: 992px){
    margin-left: 30px;
  }

  @media (max-width: 768px){
    margin-left: 26px;
  }

  span{
    opacity: 0.7;
  }
}

.reset-search{
  margin-left: 4px;
  transition: all 0.2s ease-in-out;
  font-weight: bold;
  text-decoration: underline;
  opacity: 0.7;

  &:active, &:visited, &:focus{
    color: $black;
  }

  &:hover{
    opacity: 1;
  }
}

.problem-list-section{
  padding-top: 8px;
  overflow: visible;

  .list-header {
    margin-top: 50px;

    @media (max-width: 568px){
      margin-top: 32px;
    }

    .nav.nav-tabs {
      margin-bottom: 18px;

      >li{
        >a{
          @media (max-width: 480px){
            padding: 10px 8px;
          }
        }
      }
    }
  }
}

#unassociated-problems{
  position: relative;
  margin: 0 auto 75px auto;
  padding: 0 15px 0 15px;

  .sa-table.container {
    position: relative;
    max-width: 1170px;
    background: $white;
    padding-left: 0;
    padding-right: 0;
    border-radius: 4px;
    margin-top: 14px!important;
    box-shadow:  0 2px 10px rgba(0, 0, 0, 0.1);

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent $white transparent;
      border-style: solid;
    }
  }

  .sa-table-body{
    @media (max-width: 768px){
      padding: 0;
    }
  }

  .sa-table-row{
    p{
      margin-bottom: 0;
    }

    @media (max-width: 768px){
      box-shadow: none;
      border-radius: 0;
      border-bottom: 1px solid #f0f0f0;

      &:hover{
        box-sizing: none;
      }

      &:last-child{
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }

  .close-btn{
    display: block;
    position: absolute;
    top: -12px;
    right: -12px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #eee url('/../images/close-icon.svg') no-repeat center center;
    transform: translateZ(100px);
    z-index: 100;
    box-shadow: 0 1px 3px rgba(0,0,0,0.15);
    transition: all 0.2s ease-in-out;

    &:hover{
      background-color: #e0e0e0;
    }

    &:active{
      background-color: #eee;
    }
  }
}

.sa-table-problems{
  margin-top: 64px;

  @media (max-width: 568px){
    margin-top: 32px;
  }

  .sa-table-cell{
    @media (max-width: 480px){
      padding: 20px 8px 12px 8px !important;

      &:before{
        top: 4px;
        color: #999;
      }
    }

    &.problem{
      >.row{
        @media (max-width: 768px){
          margin-left: 0;
          margin-right: 0;
          padding-bottom: 8px;
          margin-bottom: 8px;
          border-bottom: 1px solid #f5f5f5;

          &:last-child{
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: 0;
          }

          >.col-xs-9{
            padding-left: 0;
          }
        }
      }

      .problem-text{
        @media (max-width: 768px){
          margin-bottom: 0;
        }
      }
    }

    &.homes{
      @media (max-width: 768px){
        >p, >a{
          margin-bottom: 0;
          display: inline-block;

          &:not(:last-child):after{
            content: '/';
            display: inline-block;
            margin: 0 5px;
          }
        }
      }
    }

    &.photos{
      @media (max-width: 768px){
        float: left;
        width: 25%;
        padding: 0!important;
        margin-top: 8px;
      }
    }
  }

  .problem-text{
    @media (max-width: 768px){
      line-height: 18px;
    }

    .problem-indicator{
      @media (max-width: 768px){
        top: 7px;
      }
    }
  }
}

.problem-list-info{
  text-align: center;
  margin-bottom: 0px;
  padding: 0 15px;

  @media (max-width: 480px){
    padding: 0 38px;
  }

  @media (max-width: 360px){
    padding: 0 24px;
  }

  p{
    margin-bottom:0;
  }
}

.bulk-text-info{
  color: transparentize($black, 0.3);

  #unassociated-problems-toggle{
    display: inline-block;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;

    .chevron{
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 0;
      position: relative;
      top: 1px;
      border-width: 4px 4px 0 4px;
      border-color: $black transparent transparent transparent;
      border-style: solid;
      margin-left: 3px;
      opacity: 0.7;
      transition: all 0.2s ease-in-out;

      &.flipped{
        transform: rotate(180deg);
      }
    }

    &:hover{
      color: $black;

      &:after{
        opacity: 1;
      }
    }
  }
}

.bulk-ack{
  background: #f2f2f2;
  border: 1px solid #d4d4d4;
  border-radius: 20px;
  padding: 2px 8px;
  color: #000000;
  font-size: 10px;
  line-height: 16px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.2s ease-out;

  &:hover{
    background: #f0f0f0;
  }

  &.ack-bulk-active{
    background: $black;
    color: $white;
    border-color: $black;
  }
}
